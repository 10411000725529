import React, { useEffect, useState } from "react";
import InputBox from "../Components/InputBox";
import copy from "copy-to-clipboard";
import Loader from "../Components/Loader";

import "../Assets/Style/Components/ButtonStyle.css";
import "../Assets/Style/Config/Icons.css";
import "../Assets/Style/Pages/AddCompanyContact.css";
import { EMAIL_REGEX, PHONE_NUMBER_REGEX } from "../Constants/RegexPatterns";
import insertContactData from "../services/insertContactData";
import getCompaniesWithoutContact from "../services/getCompaniesWithoutContact";

import { BsCopy as CopyIcon } from "react-icons/bs";
import { FaLessThan } from "react-icons/fa";

const AddCompanyContact = () => {
  const [company, setCompany] = useState({});
  const [refreshCompany, setRefreshCompany] = useState(false);
  const [inputData, setInputData] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    const temp = await getCompanyData();
    if (temp) {
      setCompany(temp);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // #region CHANGE INPUT
  const changeInput = (e, datatype) => {
    setInputData({
      ...inputData,
      [e.target.name]: e.target.value,
    });
  };

  // #region GET COMPANY DATA
  const getCompanyData = async () => {
    setIsLoading(true);
    try {
      const response = await getCompaniesWithoutContact();
      if (response.status === 200) {
        setIsLoading(false);
        return response.data.company;
      }
    } catch (error) {
      console.log(error);
      // alert(error);
      return;
    }
  };

  const changeContact = async (email, phoneNumber, id) => {
    setIsLoading(true);
    if (email && !EMAIL_REGEX.test(email)) {
      alert("Email je neveljaven!");
      setIsLoading(false);
      return false;
    }

    if (phoneNumber && !PHONE_NUMBER_REGEX.test(phoneNumber)) {
      alert("FORMAT TELEFONSKE ŠTEVILKE JE NEVELJAVEN!");
      setIsLoading(false);
      return false;
    }

    try {
      if (!phoneNumber) {
        phoneNumber = null;
      }

      if (!email) {
        email = null;
      }
      console.log("Test 1")
      const res = await insertContactData(email, phoneNumber, id);
      console.log(res)
      console.log("Test 2")
      window.location.reload(true);
      console.log("Test 3")
    } catch (error) {
      alert(error);
      setIsLoading(false);
      return false;
    }
  };

  // #region JSX
  return (
    <div className="add-company-box">
      {isLoading && <Loader />}

      <div className="company-data">
        {company && (
          <>
            <span>
              <span className="title">IME:</span> {company.long_name}{" "}
              <CopyIcon
                className="copy-icon"
                onClick={() => {
                  copy(company.long_name);
                }}
              />
            </span>
            <span>
              <span className="title">MATIČNA ŠT:</span>{" "}
              {company.registration_number}
            </span>
            <span>
              <span className="title">POŠTA:</span> {company.post_number},
              {company.post_name}
            </span>
            <span>
              <span className="title">STATUS:</span>{" "}
              {company.company_status_code}
            </span>
          </>
        )}
      </div>
      <div className="input-fields">
        <InputBox
          label="E-naslov: "
          id="input-bar-1"
          class="input-bar"
          labelclass="label"
          inputcontainer="input-container-center"
          name="email"
          placeholder="Vnesi E-naslov..."
          type="text"
          datatype="basic"
          onchange={changeInput}
        />
        <InputBox
          label="Telefonska številka: "
          id="input-bar-2"
          class="input-bar"
          labelclass="label"
          inputcontainer="input-container-center"
          name="phoneNumber"
          placeholder="Vnesi telefonsko številko..."
          type="text"
          datatype="basic"
          onchange={changeInput}
        />
        <div className="button-box">
          <button
            onClick={() => {
              changeContact(inputData.email, inputData.phoneNumber, company.ID);
            }}
            className="button-2"
          >
            Ne najdem
          </button>
          <button
            onClick={() => {
              changeContact(inputData.email, inputData.phoneNumber, company.ID);
            }}
            className="button-1"
          >
            Shrani
          </button>
        </div>
      </div>
    </div>
  );
};
// #region END JSX

export default AddCompanyContact;
