import React, { useState } from "react";
import InputBox from "../Components/InputBox";
import loginUser from "../services/loginUser";

import "../Assets/Style/Pages/LoginPage.css";

const LoginPage = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState({});

  const changeInput = (e, datatype) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const onEnterSubmit = (e) => {
    if (e.code === "Enter") {
      submitData();
    }
  };

  const submitData = async () => {
    try {
      const response = await loginUser(user);
      if (response.data.verified) {
        props.handleverified();
      }
    } catch (error) {
      window.alert(error.response.data.message);
    }
  };

  return (
    <div className="login-page" onKeyDown={onEnterSubmit}>
      <div className="login-box">
        <InputBox
          label="Uporabniško ime: "
          id="input-bar-1"
          class="input-bar"
          labelclass="label"
          inputcontainer="input-container-center"
          name="username"
          placeholder="Vnesi uporabniško ime..."
          type="text"
          datatype="basic"
          onchange={changeInput}
        />

        <InputBox
          label="Geslo: "
          id="input-bar-2"
          class="input-password"
          labelclass="label"
          inputcontainer="input-container-center"
          name="password"
          datatype="basic"
          placeholder="Vnesli geslo..."
          type={showPassword ? "text" : "password"}
          onchange={changeInput}
          showpassword={() => setShowPassword(!showPassword)}
        />

        <div className="login-button-box">
          <button onClick={submitData} className="button-1">
            Prijava
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
