import axios from "axios";

const getCompaniesWithoutContact = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(process.env.REACT_APP_GET_COMPANIES_WITHOUT_CONTACT)
      .catch((err) => reject(err))
      .then((res) => resolve(res));
  });
};

export default getCompaniesWithoutContact;
