import axios from "axios";

const insertContactData = (email, phoneNumber, id) => {
  return new Promise((resolve, reject) => {
    axios
      .put(process.env.REACT_APP_INSERT_CONTACT_DATA, {
        email,
        phoneNumber,
        id,
      })
      .catch((err) => reject(err))
      .then((res) => resolve(res));
  });
};

export default insertContactData;
