import React from "react";

import "../Assets/Style/Components/InputBox.css"

import { BsEye as OpenEyeIcon } from "react-icons/bs";
import { BsEyeSlash as ClosedEyeIcon } from "react-icons/bs";

const InputBox = (props) => {
  return (
    <div className={props.inputcontainer}>
      <div className="input-box">
        <label htmlFor={props.id} className={props.labelclass}>
          {props.label}
        </label>
        <div className="input-wrapper">
          <input
            type={props.type}
            placeholder={props.placeholder}
            id={props.id}
            className={props.class}
            name={props.name}
            onChange={(e) => props.onchange(e, props.datatype)}
            defaultValue={props.defaultvalue}
            autoComplete="on"
          />
        </div>
        {props.type === "password" && (
          <OpenEyeIcon className="eyeIconOpen" onClick={props.showpassword} />
        )}

        {props.type === "text" && props.showpassword && (
          <ClosedEyeIcon
            className="eyeIconClosed"
            onClick={props.showpassword}
          />
        )}
      </div>
    </div>
  );
};

export default InputBox;
