import axios from "axios";

const loginUser = (user) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        process.env.REACT_APP_LOGIN_USER,
        { user },
        { withCredentials: true }
      )
      .catch((err) => reject(err))
      .then((res) => resolve(res));
  });
};

export default loginUser;
