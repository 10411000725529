import { useEffect, useState } from "react";
import AddCompanyContact from "./Pages/AddCompanyContact";
import LoginPage from "./Pages/LoginPage";
import { useCookies } from "react-cookie";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [cookies] = useCookies(["loggedin"]);

  useEffect(() => {
    checkForCookies();
  }, []);
  const checkForCookies = () => {
    if (cookies.loggedin === process.env.REACT_APP_USERNAME) {
      setLoggedIn(true);
    }
  };

  const handleVerified = () => {
    setLoggedIn(true);
  };

  return (
    <div className="App" onLoad={checkForCookies}>
      {!loggedIn && <LoginPage handleverified={handleVerified} />}
      {loggedIn && <AddCompanyContact />}
    </div>
  );
}

export default App;
